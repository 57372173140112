import React from "react";
import "./App.css";
import Nav from "./Nav";

function OrderSuccess() {
  return (
    <>
      <header>
        <Nav />
      </header>

      <main>
        <div style={{ padding: "20px", fontSize: "24px" }}>
          <h1>Thank you for you order!</h1>
          <p>
            Your order has been received and we are diligently working to create
            a personalized experience for you. Upon completion, we will send it
            to you for review and include various payment options for your
            convenience.
          </p>
        </div>
      </main>
    </>
  );
}

export default OrderSuccess;
