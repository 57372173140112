/* eslint-disable no-undef */
import React, { useCallback } from "react";
import "./App.css";
import Nav from "./Nav";
import { useNavigate } from "react-router-dom";
import Button from "./Components/Button";
import coffehand from "./coffee-in-hands-raw.jpg";
import coffeebeans from "./coffee-pour-over.jpg";

function App() {
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => {
    if (gtag) {
      gtag("event", "call-to-action", {
        screen_name: "Home",
      });
    }

    navigate("/plans");
  }, [navigate]);

  return (
    <>
      <header>
        <Nav />
      </header>

      <main className="landing-page">
        <div className="main-pitch">
          <p>
            Life's too short for bad coffee. Let us enhance your brewing
            experience and make the most of life.
          </p>
        </div>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            height: "fit-content",
            width: "fit-content",
          }}
        >
          <Button text="See plans" onClick={handleOnClick} />
        </div>
        <div className="image-container">
          <img src={coffehand} className="App-coffee" alt="coffee" />
        </div>
        <div className="main-pitch">
          <p>
            Our organic ethically sourced coffee beans are carefully selected
            from small-scale farmers who use sustainable growing methods,
            ensuring the highest quality and taste.
          </p>
        </div>
        <div className="image-container">
          <img
            src={coffeebeans}
            className="App-coffee-beans"
            alt="coffee-beans"
          />
        </div>
        <div className="main-pitch">
          <p>
            <b>How it works?</b>
          </p>
          <p>
            With our subscription service, you'll receive a fresh batch of beans
            delivered straight to your door on your chosen cadence.
            <br />
            <br /> These beans are hand curated based on your taste profile
            giving you a chance to explore and enjoy new varieties. <br />
            <br /> There is no commitment required, you can cancel your
            subscription at anytime
          </p>
        </div>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            height: "fit-content",
            width: "fit-content",
            marginBottom: "20px",
          }}
        >
          <Button text="See plans" onClick={handleOnClick} />
        </div>
      </main>
    </>
  );
}

export default App;
