import React from "react";

function WholeBeanOrGround({
  selectedGroundSetting,
  handleSelectGroundSetting,
}) {
  return (
    <>
      <p>Would you prefer whole beans or ground?</p>

      <div class="choose-plan-container">
        <div
          className={
            selectedGroundSetting === "whole-beans"
              ? "plan-button selected"
              : "plan-button"
          }
          onClick={() => handleSelectGroundSetting("whole-beans")}
        >
          <div class="plan-header">Whole beans</div>
        </div>
      </div>

      <div class="choose-plan-container">
        <div
          className={
            selectedGroundSetting === "ground"
              ? "plan-button selected"
              : "plan-button"
          }
          onClick={() => handleSelectGroundSetting("ground")}
        >
          <div class="plan-header">Ground</div>
        </div>
      </div>
    </>
  );
}

export default WholeBeanOrGround;
