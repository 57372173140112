import React from "react";

function SelectBeanType({ selectedBeanTypes, handleSelectedBeanType }) {
  return (
    <>
      <p>Select bean types</p>

      <div class="choose-plan-container">
        <div
          className={
            selectedBeanTypes.includes("bright-and-acidic")
              ? "plan-button selected"
              : "plan-button"
          }
          onClick={() => handleSelectedBeanType("bright-and-acidic")}
        >
          <div class="plan-header">Bright and acidic</div>
          <div class="plan-description">
            Often associated with light roast coffee, these beans have a high
            acidity that provides a tangy and crisp taste.
          </div>
        </div>
      </div>

      <div class="choose-plan-container">
        <div
          className={
            selectedBeanTypes.includes("bold-and-rich")
              ? "plan-button selected"
              : "plan-button"
          }
          onClick={() => handleSelectedBeanType("bold-and-rich")}
        >
          <div class="plan-header">Bold and rich</div>
          <div class="plan-description">
            Often associated with dark roast coffee, these beans have a low
            acidity and a strong, robust flavor.
          </div>
        </div>
      </div>

      <div class="choose-plan-container">
        <div
          className={
            selectedBeanTypes.includes("smooth-and-balanced")
              ? "plan-button selected"
              : "plan-button"
          }
          onClick={() => handleSelectedBeanType("smooth-and-balanced")}
        >
          <div class="plan-header">Smooth and balanced</div>
          <div class="plan-description">
            These coffees have a moderate acidity and a well-rounded flavor
            profile.
          </div>
        </div>
      </div>

      <div class="choose-plan-container">
        <div
          className={
            selectedBeanTypes.includes("fruity-and-floral")
              ? "plan-button selected"
              : "plan-button"
          }
          onClick={() => handleSelectedBeanType("fruity-and-floral")}
        >
          <div class="plan-header">Fruity and floral</div>
          <div class="plan-description">
            These coffees have bright, fruity and floral notes, with a balanced
            acidity.
          </div>
        </div>
      </div>

      <div class="choose-plan-container">
        <div
          className={
            selectedBeanTypes.includes("nutty-and-chocolatey")
              ? "plan-button selected"
              : "plan-button"
          }
          onClick={() => handleSelectedBeanType("nutty-and-chocolatey")}
        >
          <div class="plan-header">Nutty and chocolatey</div>
          <div class="plan-description">
            These coffees have notes of nuts and chocolate, with a smooth and
            creamy body.
          </div>
        </div>
      </div>

      <div class="choose-plan-container">
        <div
          className={
            selectedBeanTypes.includes("caramel-and-sweet")
              ? "plan-button selected"
              : "plan-button"
          }
          onClick={() => handleSelectedBeanType("caramel-and-sweet")}
        >
          <div class="plan-header">Caramel and sweet</div>
          <div class="plan-description">
            These coffees have a caramel-like sweetness and a smooth body.
          </div>
        </div>
      </div>

      <div class="choose-plan-container">
        <div
          className={
            selectedBeanTypes.includes("spicy-and-earthy")
              ? "plan-button selected"
              : "plan-button"
          }
          onClick={() => handleSelectedBeanType("spicy-and-earthy")}
        >
          <div class="plan-header">Spicy and earthy</div>
          <div class="plan-description">
            These coffees have a mix of earthy and spicy notes, with a medium to
            full body.
          </div>
        </div>
      </div>

      <div class="choose-plan-container" style={{ paddingBottom: "20px" }}>
        <div
          className={
            selectedBeanTypes.includes("herbal-and-tea-like")
              ? "plan-button selected"
              : "plan-button"
          }
          onClick={() => handleSelectedBeanType("herbal-and-tea-like")}
        >
          <div class="plan-header">Herbal and tea-like</div>
          <div class="plan-description">
            These coffees have a herbal or tea-like taste, with a lighter body
            and acidity.
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectBeanType;
