import React from "react";

function HowOften({ cadence, setCadence }) {
  return (
    <>
      <p>
        We'll send you two 6oz bags per delivery. How often would you like these
        deliveries?
      </p>

      <div class="choose-plan-container">
        <div
          className={
            cadence === "every-week" ? "plan-button selected" : "plan-button"
          }
          onClick={() => setCadence("every-week")}
        >
          <div class="plan-header">Every week</div>
          <div class="plan-description">$18</div>
        </div>
      </div>

      <div class="choose-plan-container">
        <div
          className={
            cadence === "every-two-weeks"
              ? "plan-button selected"
              : "plan-button"
          }
          onClick={() => setCadence("every-two-weeks")}
        >
          <div class="plan-header">Every two weeks</div>
          <div class="plan-description">$20</div>
        </div>
      </div>

      <div class="choose-plan-container">
        <div
          className={
            cadence === "every-month" ? "plan-button selected" : "plan-button"
          }
          onClick={() => setCadence("every-month")}
        >
          <div class="plan-header">Every month</div>
          <div class="plan-description">$22</div>
        </div>
      </div>

      <div class="choose-plan-container" style={{ paddingBottom: "20px" }}>
        <div
          className={
            cadence === "every-two-months"
              ? "plan-button selected"
              : "plan-button"
          }
          onClick={() => setCadence("every-two-months")}
        >
          <div class="plan-header">Every two months</div>
          <div class="plan-description">$25</div>
        </div>
      </div>
    </>
  );
}

export default HowOften;
