import React from "react";
import "./App.css";
import Nav from "./Nav";

function About() {
  return (
    <>
      <header>
        <Nav />
      </header>

      <main style={{ height: "100%", overflow: "scroll" }}>
        <div style={{ padding: "20px", fontSize: "24px" }}>
          <p>
            Welcome to Brew Basket, where coffee lovers can enjoy a rich and
            flavorful cup while supporting sustainable and ethical practices. We
            are dedicated to sourcing the finest coffee beans from around the
            world and bringing them to your cup, with a focus on ethical
            sourcing and organic farming.
          </p>
          <p>
            Our journey began with a passion for coffee and a commitment to
            doing things the right way. We believe that coffee should not only
            taste great, but also be grown and harvested in a way that is good
            for farmers, communities, and the environment. That's why we work
            directly with small-scale farmers to bring you coffee that is both
            delicious and sustainable.
          </p>
          <p>
            Our coffee beans are carefully selected from regions known for
            producing high-quality beans, and are certified organic and
            ethically sourced. This means that our coffee is grown without the
            use of harmful chemicals, and that the farmers who grow it are paid
            a fair price for their hard work.
          </p>
          <p>
            We believe that the perfect cup of coffee starts with the perfect
            bean, and we take great care to roast each one to bring out its
            unique flavor and character. Whether you prefer a light and bright
            coffee, or a full-bodied and bold roast, we have something for
            everyone.
          </p>
          <p>
            At Brew Basket, we are proud to offer a product that is not only
            delicious but also makes a positive impact on the world. So why not
            join us on our journey to make every cup of coffee count? We
            guarantee that you will taste the difference in every sip.
          </p>
        </div>
      </main>
    </>
  );
}

export default About;
