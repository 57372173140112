import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./brewbasketlogo.png";
import "./Nav.css";

function Navigation() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const handleNavMenuClick = () => {
    console.log("here");
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  return (
    <nav className="nav">
      <div className="container">
        <div className="logo" onClick={() => navigate("/")}>
          <img src={logo} className="App-logo" alt="logo" />
          <span className="logo-text">Brew Basket</span>
        </div>
        <div
          id="mainListDiv"
          className={isOpen ? "main_list show_list" : "main_list"}
        >
          <ul className="navlinks">
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/plans">Order</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </div>
        <span
          className={isOpen ? "navTrigger active" : "navTrigger"}
          onClick={handleNavMenuClick}
        >
          <i></i>
          <i></i>
          <i></i>
        </span>
      </div>
    </nav>
  );
}

export default Navigation;
