/* eslint-disable no-undef */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./plans.css";
import Nav from "./Nav";
import Button from "./Components/Button";
import SelectBeanType from "./Components/SelectBeanType";
import WholeBeanOrGround from "./Components/WholeBeanOrGround";
import DelivereyCadence from "./Components/DelivereyCadence";
import Info from "./Components/Info";

function Plans() {
  const navigate = useNavigate();
  const [selectedBeanTypes, setSelectedBeanTypes] = useState([]);
  const [selectedGroundSetting, setSelectedGroundSetting] = useState();
  const [cadence, setCadence] = useState();
  const [wizardStep, setWizardStep] = useState(1);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();

  const handleSelectedBeanType = (name) => {
    const selectedClone = [...selectedBeanTypes];
    const index = selectedClone.indexOf(name);
    if (index > -1) {
      selectedClone.splice(index, 1);
      setSelectedBeanTypes(selectedClone);
    } else {
      setSelectedBeanTypes([...selectedClone, name]);
    }
  };

  const handleSubmitOrder = async () => {
    if (gtag) {
      gtag("event", "order-submit", {
        screen_name: "submit-page",
      });
    }
    const response = await fetch(
      "https://an0t80jqz8.execute-api.us-east-1.amazonaws.com/dev/customer-order",
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          beanTypes: selectedBeanTypes.join(", "),
          groundSetting: selectedGroundSetting,
          cadence: cadence,
        }),
      }
    );

    if (response.status === 200) {
      navigate("/order-success");
    }
  };

  const shouldShowSubmit = () => {
    if (firstName && lastName && email) {
      return true;
    }

    return false;
  };

  const shouldShowNext = () => {
    if (wizardStep === 1 && selectedBeanTypes.length > 0) {
      if (gtag) {
        gtag("event", "selected-bean-type", {
          screen_name: "select-bean",
        });
      }
      return true;
    }
    if (wizardStep === 2 && selectedGroundSetting) {
      if (gtag) {
        gtag("event", "selected-ground-setting", {
          screen_name: "select-ground",
        });
      }
      return true;
    }
    if (wizardStep === 3 && cadence) {
      if (gtag) {
        gtag("event", "selected-cadence", {
          screen_name: "select-ground",
        });
      }
      return true;
    }

    return false;
  };

  return (
    <>
      <header>
        <Nav />
      </header>

      <main>
        <div className="plan-container">
          {wizardStep === 1 && (
            <SelectBeanType
              selectedBeanTypes={selectedBeanTypes}
              handleSelectedBeanType={handleSelectedBeanType}
            />
          )}
          {wizardStep === 2 && (
            <WholeBeanOrGround
              selectedGroundSetting={selectedGroundSetting}
              handleSelectGroundSetting={setSelectedGroundSetting}
            />
          )}
          {wizardStep === 3 && (
            <DelivereyCadence cadence={cadence} setCadence={setCadence} />
          )}
          {wizardStep === 4 && (
            <Info
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              email={email}
              setEmail={setEmail}
            />
          )}
        </div>
      </main>

      <footer>
        <div
          style={{
            display: "flex",
            justifyContent: wizardStep > 1 ? "space-between" : "end",
            marginRight: "auto",
            marginLeft: "auto",
            padding: "20px",
            maxWidth: "1000px",
          }}
        >
          {wizardStep > 1 && (
            <Button
              text="Back"
              height="50px"
              width="100px"
              onClick={() => setWizardStep(wizardStep - 1)}
            />
          )}
          {shouldShowNext() && wizardStep < 4 && (
            <Button
              text="Next"
              height="50px"
              width="100px"
              onClick={() => setWizardStep(wizardStep + 1)}
            />
          )}
          {shouldShowSubmit() && wizardStep === 4 && (
            <Button
              text="Submit"
              height="50px"
              width="100px"
              onClick={handleSubmitOrder}
            />
          )}
        </div>
      </footer>
    </>
  );
}

export default Plans;
