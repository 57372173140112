import React from "react";

function Info({
  email,
  setEmail,
  firstName,
  setFirstName,
  lastName,
  setLastName,
}) {
  return (
    <>
      <p>Order Information</p>

      <input
        type="text"
        placeholder="First name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <input
        type="text"
        placeholder="Last name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
      <input
        type="text"
        placeholder="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <div>
        <span>
          Upon submitting your order, we will send you a customized experience
          for a final review before finalizing the order.
        </span>
      </div>
    </>
  );
}

export default Info;
