import React from "react";
import "./App.css";
import Nav from "./Nav";

function About() {
  return (
    <>
      <header>
        <Nav />
      </header>

      <main style={{ height: "100%", overflow: "scroll" }}>
        <div style={{ padding: "20px", fontSize: "24px" }}>
          <p>BrewBasket is brought to you by Happybit Innovations LLC.</p>
          <p>If you require assistance please email support@happybit.io</p>
          <p>
            Mailing address: 3400 Cottage Way, Ste G2 #14341 Sacramento ,
            California 95825
          </p>
        </div>
      </main>
    </>
  );
}

export default About;
